import React from 'react';
import {Grid as DreiGrid} from '@react-three/drei';

const Grid: React.FC = () => {
  return (
    <>
      <DreiGrid
        infiniteGrid
        cellSize={1}
        sectionSize={10}
        fadeDistance={50}
        fadeStrength={1.5}
      />
    </>
  );
};

export default Grid;
