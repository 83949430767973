import React, {
  RefObject,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls as DreiTransformControls, Html } from '@react-three/drei';
import { TransformControls as ThreeTransformControls } from 'three/examples/jsm/controls/TransformControls';

import { CanvasBlocks } from './types';
import { getLessonBlocks } from '../Validation/RequirePositons';

// validation function
import { AssemblyValidator } from '../Validation/newValidation';


interface InteractiveGLBModelProps {
  url: string;
  position: [number, number, number];
  rotation: [number, number, number];
  gltf: THREE.Group;
  onPositionChange: (newPosition: [number, number, number]) => void;
  onRotationChange: (newPosition: [number, number, number]) => void;
  isSelected: boolean;
  onSelect: () => void;
  setIsOrbitEnabled: (enabled: boolean) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  callBlockPosition: (newPosition: [number, number, number]) => void;
  modelFiles: CanvasBlocks[];
  orbitControlsRef: RefObject<OrbitControls> | ((instance: any) => void);
  setSelectedBlock: () => void;
  selectedBlock: string | null
  isModify?: Boolean
}
type Mode = 'rotate' | 'scale' | 'translate' | undefined;


// Initialize GLTFLoader
const loader = new GLTFLoader();
const scene = new THREE.Scene();

const InteractiveGLBModel: React.FC<InteractiveGLBModelProps> = React.memo(
  ({
    url,
    position,
    rotation,
    gltf,
    isModify,
    onPositionChange,
    onRotationChange,
    isSelected,
    onSelect,
    setIsOrbitEnabled,
    onDelete,
    onDuplicate,
    // onRotate,
    callBlockPosition,
    modelFiles,
    orbitControlsRef,
    setSelectedBlock,
    selectedBlock
  }) => {

    const ref = useRef<THREE.Group>(null);
    const rotationRef = useRef<[number, number, number]>([0, 0, 0]);
    const [mode, setMode] = useState<Mode>('translate');
    const [controls, setControls] = useState<OrbitControls | null>(null);
    const blockPositionRef = useRef<[number, number, number]>([0, 0, 0]);

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onPointerDown = useCallback(
      (e: ThreeEvent<PointerEvent>) => {
        e.stopPropagation();
        if (isModify) {
          onSelect();
        }
      },[onSelect]);

    const handleOptionClick = useCallback(
      (action: 'delete' | 'duplicate' | 'rotateX' | 'rotateY' | 'rotateZ') => {
        switch (action) {
          case 'delete':
            onDelete();
            break;
          case 'duplicate':
            onDuplicate();
            break;
        }
      },
      [onDelete, onDuplicate], //onDelete, onDuplicate, onRotate
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (!mode) {
          setSelectedBlock();
        }
      };
      const handleKeyPress = (event: KeyboardEvent) => {
        // Only handle key events if this component is selected
        if (!isSelected) return;

        // Prevent default behavior for these keys
        if (['r', 'R', 'e', 'E', 'Delete', 'Backspace'].includes(event.key)) {
          event.preventDefault();
        }

        switch (event.key) {
          case 'R':
          case 'r':
            onSelect();
            setMode('rotate');
            break;
          case 'E':
          case 'e':
            onSelect();
            setMode('translate');
            break;
          case 'Delete':
          case 'Backspace':
            handleOptionClick('delete');
            break;
          default:
            break;
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [isSelected, mode, onSelect, handleOptionClick, setSelectedBlock]); // Add dependencies here


    const urls: string[] = [];
    const snapPointNames: string[] = [];
    modelFiles.forEach(element => {
      const gltfs = element.gltf?.children
        .filter(item => item.name.includes('Anchor'))
        .map(item => item.name);

      urls.push(element.url);
      snapPointNames.push(...gltfs);
    });

    interface Config {
      modelPaths: string[];
      snapPointNames: string[];
      snappingThreshold: number;
    }

    let config: Config = {
      modelPaths: urls,
      snapPointNames: snapPointNames,
      snappingThreshold: 1,
    };

    function loadModel(loader: any, path: string) {
      return new Promise((resolve, reject) => {
        loader.load(
          path,
          (gltf: any) => resolve(gltf.scene),
          undefined,
          reject,
        );
      });
    }
    function extractSnapPoints(model: any, snapPointCriteria: any) {
      if (model && model.children) {
        return model.children
          .filter((child: any) => snapPointCriteria(child))
          .map((child: any) => {
            const worldPos = new THREE.Vector3();
            child.getWorldPosition(worldPos);
            return worldPos;
          });
      }
    }
    async function setupModels(scene: any, loader: any, config: Config) {
      try {
        const models = await Promise.all(
          config.modelPaths.map((path: any) => loadModel(loader, path)),
        );

        models.forEach((model: any) => scene.add(model));

        const snapModel = models[Math.abs(models.length - 2)];

        const snapPointCriteria = (child: any) =>
          config.snapPointNames.includes(child.name);

        const snapPointWorldPositions = extractSnapPoints(
          snapModel,
          snapPointCriteria,
        );

        if (ref.current && ref.current.position) {

          snapPointWorldPositions?.forEach((point: any) => {
            const distance = ref?.current?.position.distanceTo(point) || 0;
            if (
              // distance < closestDistance &&
              distance <= config.snappingThreshold
            ) { }
          });

        }
      } catch (error) {
        console.error('Error loading models:', error);
      }
    }
    setupModels(scene, loader, config);

    useEffect(() => {
      if (typeof orbitControlsRef !== 'function') {
        if (orbitControlsRef.current) {
          setControls(orbitControlsRef.current);
        }
      }
    }, [orbitControlsRef]);

    // Helper function to validate block position and rotation
    const validateBlockPlacement = useCallback(() => {

      const urlParams = new URLSearchParams(window.location.search);
      const lessonId = urlParams.get('lessonId');

      // Get the correct blocks array for the current lesson
      const Blocks = getLessonBlocks(lessonId);

      
      // Find the current block in currentBlocks array
      const currentBlock = modelFiles.find(block => block.id === selectedBlock);

      if (!currentBlock) return;

      const validator = new AssemblyValidator(Blocks, modelFiles);
      const status = validator.getBlockStatus(currentBlock.id);

      if (!status.isCorrect) {
        let message = '';
        if (!status.position && !status.rotation) {
          message = 'Position and rotation need adjustment';
        } else if (!status.position) {
          // Find matching required block to show target position
          // if (status?.requiredBlock) {
          //   const [tx, ty, tz] = status?.requiredBlock.position;
          //   const [cx, cy, cz] = currentBlock.position;
          //   const diffX = Math.abs(tx - cx);
          //   const diffY = Math.abs(ty - cy);
          //   const diffZ = Math.abs(tz - cz);

          //   if (diffX > 0.5) message += `Move ${diffX > 0 ? 'right' : 'left'} `;
          //   if (diffY > 0.5) message += `Move ${diffY > 0 ? 'up' : 'down'} `;
          //   if (diffZ > 0.5) message += `Move ${diffZ > 0 ? 'forward' : 'backward'}`;

          //   message = message.trim() || 'Adjust position';
          // } else {
          // }
          message = 'Position needs adjustment';
        } else if (!status.rotation) {
          message = 'Rotation needs adjustment';
        }

        setErrorMessage(message);
        setShowError(true);
      } else {
        setShowError(false);
      }
    }, [modelFiles, selectedBlock]);

    // Handle transform end
    const handleTransformEnd = useCallback(() => {
      if (controls) {
        if (mode === 'translate') {
          onPositionChange(blockPositionRef.current);
          callBlockPosition(blockPositionRef.current);
        } else if (mode === 'rotate') {
          onRotationChange(rotationRef.current);
        }
        controls.enabled = true;
        validateBlockPlacement();
      }
    }, [controls, mode, onPositionChange, onRotationChange, callBlockPosition, validateBlockPlacement]);


    return isSelected ? (
      <DreiTransformControls
        size={0.5}
        mode={mode}
        onMouseDown={() => {
          if (controls) {
            controls.enabled = false; // Disable controls on mouse down
          }
        }}
        onMouseUp={handleTransformEnd}
        onChange={event => {
          if (event && event.target) {
            const target = event.target as unknown as ThreeTransformControls;
            if (ref.current && target && target.object) {
              // Get position
              const worldPosition = new THREE.Vector3();
              ref.current.getWorldPosition(worldPosition);
              blockPositionRef.current = [
                Math.round(worldPosition.x * 100) / 100,
                Math.round(worldPosition.y * 100) / 100,
                Math.round(worldPosition.z * 100) / 100,
              ];

              // Get rotation
              const transformedObject = target.object;
              target.setTranslationSnap(1);
              target.setRotationSnap(THREE.MathUtils.degToRad(15));
              target.setScaleSnap(0.30);
              const rotation_position = transformedObject.rotation;

              // Update rotation reference directly (in radians)
              rotationRef.current = [
                rotation_position.x,
                rotation_position.y,
                rotation_position.z,
              ];
            }
          }
        }}
        position={position}
        rotation={rotation}>
        <group ref={ref} onPointerDown={onPointerDown}>
          <primitive object={gltf} />
          {showError && (
            <Html position={[5, 5, 0]} center>
              <div className="bg-red text-white px-2 py-1 rounded text-[8px]">
                {errorMessage}
              </div>
            </Html>
          )}
        </group>
      </DreiTransformControls>
    ) : (
      <group
        ref={ref}
        position={position}
        rotation={rotation}
        onPointerDown={onPointerDown}
      >
        <primitive object={gltf} />
      </group>
    );
  },
);

export default InteractiveGLBModel;