export const customBlockArray = [
    {
        "type": "colour_picker",
        "message0": "%1",
        "args0": [
            {
                "type": "field_colour",
                "name": "COLOUR",
                "colour": "#ff0000"
            }
        ],
        "output": "Colour",
        "colour": "#A65BA5",
        "helpUrl": "%{BKY_COLOUR_PICKER_HELPURL}",
        "tooltip": "%{BKY_COLOUR_PICKER_TOOLTIP}",
        "extensions": ["parent_tooltip_when_inline"]
    },
    {
        "type": "when_color_is",
        "message0": "%1 When color is %2",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "WHEN_COLOR_DROPDOWN",
                "options": [
                    ["A", "A"],
                    ["B", "B"],
                    ["C", "C"],
                    ["D", "D"],
                    ["E", "E"],
                    ["F", "F"],
                ]
            },
            {
                "type": "field_colour",
                "name": "WHEN_COLOR_PICKER",
                "colour": "#ff0000",  // Default color
                colourOptions: [
                    '#4d1fa7',
                    '#efefef',
                    '#fb1a1c',
                    '#f9A32e',
                    '#fedf66',
                    '#1d9d46',
                ],
                colourTitles: [
                    'Blue',
                    'pink',
                    'light pink',
                    'dark blue',
                    'blue',
                    'light blue',
                  ],
                columns: 3,
            }
        ],
        // "output": "String",
        // "previousStatement": null,
        "nextStatement": "trigger",
        "colour": "#EDC643",
        "tooltip": "Triggers when the specified color is detected.",
        "helpUrl": "",
        "extensions": ["block_validation"]
    },
    {
        "type": "set_power_button_light",
        "message0": "Set Power Button light to %1",
        "args0": [
            {
                "type": "field_colour",
                "name": "SET_POWER_COLOR_PICKER",
                "colour": "#06DAFF",  // Default color
                colourOptions: [
                    '#4d1fa7',
                    '#efefef',
                    '#fb1a1C',
                    '#f9a32e',
                    '#fedf66',
                    '#1d9d46',
                ],
                columns: 3,
            }
        ],
        // "output": null,
        "previousStatement": null,
        "nextStatement": "action",
        "colour": "#8F60ED",
        "tooltip": "Sets the power button light to the specified color.",
        "helpUrl": "",
        "extensions": ["block_validation"]
    },
    {
        "type": "write_text",
        "message0": "Write %1",
        "args0": [
            {
                "type": "field_input",
                "name": "WRITE_TEXT",
                "text": ""
            }
        ],
        // "output": "String",
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Write text",
        "helpUrl": "",
        "extensions": ["block_validation"]
    },
    {
        "type": "play_sound_until_done",
        "message0": "Play sound %1 until done",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "SOUND_NAME",
                "options": [
                    ["LIBRARY", "Library"],
                    ["BLUE", "Blue"],
                    ["UPLOAD", "Upload"],
                ]
            }
        ],
        // "output": null,
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#904DBE",
        "tooltip": "Plays the specified sound until it completes.",
        "helpUrl": "",
        "extensions": ["block_validation"]
    },
    {
        "type": "turn_on_for_seconds",
        "message0": "Turn on %1 for %2 seconds",
        "args0": [
            // {
            //   "type": "field_image",
            //   "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStwx33xUBEkAVHHw4cnyIKKtFW3DM2JQaMeA&s", // Update with your icon URL
            //   "width": 25,  // Width of the icon
            //   "height": 25, // Height of the icon
            //   "alt": "*",   // Alt text (shown when icon is not available)
            //   "flipRtl": false, // Optional: flip in RTL languages
            // },
            {
                type: 'field_bitmap',
                name: 'TURN_ON_BITMAP',
                width: 2,
                height: 2,
                fieldHeight: 20,
                buttons: {
                    randomize: false,
                    clear: false,
                },
                colours: {
                    filled: "#ffffff",
                    empty: "#4D1FA7"
                },
                value: [
                    [1, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                ],
            },
            {
                "type": "field_input",
                "name": "TURN_ON_SECOND",
            }
        ],
        // "output": null,
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Turn on for a specified time",
        "helpUrl": "",
        "extensions": ["block_validation"],
    },
    {
        "type": "stop_action",
        "message0": "Stop %1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "STOP",
                "options": [
                    ["ALL", "All"],
                    ["THIS STACK", "This Stack"],
                    ["AND EXIT PROGRAM", "And Exit Program"]
                ]
            }
        ],
        // "output": "string",
        "previousStatement": null,
        // "nextStatement": false,
        "colour": "#C5912E",
        "tooltip": "Stops all processes.",
        "helpUrl": "",
        "extensions": ["block_validation"]
    },
    {
        "type": "turn_on",
        "message0": "Turn on %1",
        "args0": [
            {
                type: 'field_bitmap',
                name: 'FIELDNAME',
                width: 2,
                height: 2,
                fieldHeight: 20,
                buttons: {
                    randomize: false,
                    clear: false,
                },
                colours: {
                    filled: "#ffffff",
                    empty: "#4D1FA7"
                },
                value: [
                    [1, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                    [0, 0, 0, 0, 0],
                ],
            },
        ],
        // "output": null,
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Turn on",
        "helpUrl": "",
        "extensions": ["block_validation"]
    },
    {
        "type": "turn_off_pixel",
        "message0": "Turn off pixel",
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Turn off pixel",
        "helpUrl": "",
        "check": ["xyz"],
    },
    {
        "type": "set_pixel",
        "message0": "Set Pixel brightness to %1 %",
        "args0": [
            {
                "type": "field_input",
                "name": "TEXT",
                "text": ""
            }
        ],
        "check": ["xyz"],
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Set Pixel brightness",
        "helpUrl": ""
    },
    {
        "type": "set_pixel_at",
        "message0": "Set Pixel at %1 , %2 to %3",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "pixelone",
                "options": [
                    ["1", "1"],
                    ["2", "2"],
                    ["3", "3"],
                    ["4", "4"],
                    ["5", "5"],
                ]
            },
            {
                "type": "field_dropdown",
                "name": "pixeltwo",
                "options": [
                    ["1", "1"],
                    ["2", "2"],
                    ["3", "3"],
                    ["4", "4"],
                    ["5", "5"],
                ]
            },
            {
                "type": "field_input",
                "name": "TEXT",
                "text": ""
            },
        ],
        "check": ["xyz"],
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Set Pixel at specific numbers",
        "helpUrl": ""
    },
    {
        "type": "rotate",
        "message0": "Rotate %1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "pixelone",
                "options": [
                    ["ALL", "All"]
                ]
            },
        ],
        "check": ["xyz"],
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Rotate",
        "helpUrl": ""
    },
    {
        "type": "light_up",
        "message0": "%1 Light up %2",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "optionone",
                "options": [
                    ["ALL", "All"]
                ]
            },
            {
                "type": "field_dropdown",
                "name": "optiontwo",
                "options": [
                    ["ALL", "All"]
                ]
            },
        ],
        "check": ["xyz"],
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#8F60ED",
        "tooltip": "Rotate",
        "helpUrl": ""
    },
]