import { blocklyTheme } from "./ThemeConfig";

export const workspaceConfiguration = {
  // Disable trashcan if not needed
  trashcan: false,
  collapse: false,
  theme: blocklyTheme,
  // Grid settings
  grid: {
    spacing: 20,
    length: 3,
    colour: '#ccc',
    snap: true
  },

  // Zoom controls
  zoom: {
    controls: true,
    wheel: false,
    startScale: 1,
    maxScale: 3,
    minScale: 0.3
  },
  // Renderer choice
  renderer: 'zelos', //zelos, geras, 'thrasos

};
