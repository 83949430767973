  import * as Blockly from 'blockly';
  import { BLOCK_DISPLAY_NAMES, BlockType, FIELD_VALIDATION_RULES, VALID_BLOCK_ORDER, ValidationState } from './Typs';
  import toast from 'react-hot-toast';


  // Global state to track if we should check fields
  const validationState: ValidationState = {
    shouldCheckFields: false
  };
  interface WarningState {
    sequenceWarningVisible: boolean;
    lastValidationTime: number;
    blockWarnings: Map<string, string>;
  }

  interface FieldValidationResult {
    isValid: boolean;
    message: string;
  }


  const warningState: WarningState = {
    sequenceWarningVisible: false,
    lastValidationTime: 0,
    blockWarnings: new Map()
  };

  function getBlockWarning(block: Blockly.Block | null): string | null {
    if (!block) return null;
    return warningState.blockWarnings.get(block.id) || null;
  }

  function setBlockWarning(block: Blockly.Block | null, warning: string | null) {
    if (!block) return;
    // Set the warning in the state
    warningState.blockWarnings.set(block.id, warning || '');
    // Update the block's warning text
    block.setWarningText(warning);
  }


  function setSequenceWarning(block: Blockly.Block) {
    const warning = 'Complete the sequence with all required blocks in order:\n' +
      '1. When Color Is\n' +
      '2. Set Power Button Light\n' +
      '3. Write Text\n' +
      '4. Play Sound\n' +
      '5. Turn On For Seconds\n' +
      '6. Stop Action';

    block.setWarningText(warning);
    setBlockWarning(block, warning);
  }

  // Validation helper functions
  function hasBlocks(workspace: Blockly.Workspace): boolean {
    return workspace?.getAllBlocks(false).length > 0;
  }


  // Button click handler
  export function onValidationButtonClick(workspace: Blockly.Workspace, actionFrom?: string): boolean | undefined {
    const validation = handleValidationCheck(workspace);

    if (validation.isValid) {
      return true
    } else {
      if (!actionFrom) {
        toast(validation.message, { duration: 2000, id: "error" })
      }
      return false
    }
  }

  // Main validation handler
  export function handleValidationCheck(workspace: Blockly.Workspace): {
    isValid: boolean;
    message: string;
  } {
    // Reset states
    resetBlockStates(workspace);

    // Step 1: Check if workspace has any blocks
    if (!hasBlocks(workspace)) {
      return {
        isValid: false,
        message: 'No blocks found in workspace. Please add blocks to continue.'
      };
    }

    // Step 2: Find all sequences starting with when_color_is
    const startBlocks = workspace?.getAllBlocks(false).filter(
      block => block.type === 'when_color_is'
    );

    if (startBlocks.length === 0) {
      return {
        isValid: false,
        message: 'At least one "When Color Is" block is required.'
      };
    }

    // Step 3: Check if all sequences are complete and valid
    let invalidSequenceFound = false;

    for (const startBlock of startBlocks) {
      const isValidSequence = isCompleteSequencePresent(startBlock);
      if (!isValidSequence) {
        invalidSequenceFound = true;
        break;
      }
    }

    if (invalidSequenceFound) {
      return {
        isValid: false,
        message: 'Required block sequence is incomplete or incorrect. Please check the sequence.'
      };
    }

    // Step 4: Now that all sequences are valid, check field values
    const validationResults = startBlocks.map(startBlock => {
      const sequenceValidation = validateSequenceFields(startBlock);
      return {
        block: startBlock,
        isValid: sequenceValidation.isValid,
        message: sequenceValidation.message
      };
    });

    // Check if any sequence has invalid field values
    const invalidSequences = validationResults.filter(result => !result.isValid);
    if (invalidSequences.length > 0) {
      return {
        isValid: false,
        message: 'Please enter require field values.'
      };
    }

    return {
      isValid: true,
      message: 'All sequences validated successfully!'
    };
  }

  // Function to reset all blocks' warnings and states
  function resetBlockStates(workspace: Blockly.Workspace) {
    workspace?.getAllBlocks(false).forEach(block => {
      block.setWarningText(null);
      block.setEnabled(true);
    });
  }

  // Function to check if the complete sequence exists and update warnings
  function isCompleteSequencePresent(startBlock: Blockly.Block): boolean {
    let hasCompleteSequence = false;

    // Check for complete sequence
    let currentBlock: Blockly.Block | null = startBlock;
    let foundSequence = true;
    let lastValidBlock: Blockly.Block | null = null;

    const expectedSequence: BlockType[] = [
      'when_color_is',
      'set_power_button_light',
      'write_text',
      'play_sound_until_done',
      'turn_on_for_seconds',
      'stop_action'
    ];

    for (const expectedType of expectedSequence) {
      if (!currentBlock || currentBlock.type !== expectedType) {
        foundSequence = false;
        break;
      }
      lastValidBlock = currentBlock;
      currentBlock = currentBlock.getNextBlock();
    }

    hasCompleteSequence = foundSequence;

    // Update warning visibility state
    warningState.sequenceWarningVisible = !hasCompleteSequence && validationState.shouldCheckFields;

    // Handle warnings based on sequence state
    if (warningState.sequenceWarningVisible) {
      // Use the last valid block for setting warning if currentBlock is null
      const blockForWarning = currentBlock || lastValidBlock;
      if (blockForWarning) {
        setSequenceWarning(blockForWarning);
      }
    } else {
      // Clear warnings on the entire sequence
      let block: Blockly.Block | null = startBlock;
      while (block) {
        const currentWarning = getBlockWarning(block);
        if (currentWarning && currentWarning.includes('Complete the sequence')) {
          setBlockWarning(block, null);
        }
        block = block.getNextBlock();
      }
    }

    return hasCompleteSequence;
  }

  function validateSequenceFields(startBlock: Blockly.Block): FieldValidationResult {
    let currentBlock: Blockly.Block | null = startBlock;

    while (currentBlock) {
      const fieldValidation = validateFields(currentBlock);
      if (!fieldValidation.isValid) {
        return {
          isValid: false,
          message: "Please check field value"
        };
      }
      currentBlock = currentBlock.getNextBlock();
    }

    return {
      isValid: true,
      message: 'Sequence validated successfully'
    };
  }

  // Ensure warnings are cleared in validateFields
  function validateFields(block: Blockly.Block): { isValid: boolean; warnings: string[] } {
    const currentType = block.type as BlockType;
    const warnings: string[] = [];
    let isValid = true;

    const fieldRules = FIELD_VALIDATION_RULES[currentType] || [];

    for (const rule of fieldRules) {
      const field = block.getField(rule.fieldName);
      if (!field) continue;

      const fieldValue = field.getValue();
      let isFieldValid = false;

      if (rule.type === 'bitmap') {
        // Handle bitmap validation
        isFieldValid = areArraysEqual(fieldValue, rule.expectedPattern);
      } else {
        // Handle simple validation
        if (Array.isArray(rule.expectedValue)) {
          // If expectedValue is an array, check if fieldValue exists in the array
          isFieldValid = rule.expectedValue.includes(fieldValue);
        } else {
          // If expectedValue is a single string, do direct comparison
          isFieldValid = fieldValue === rule.expectedValue;
        }
      }

      if (!isFieldValid) {
        isValid = false;
        warnings.push(rule.errorMessage);
      }
    }

    // Clear existing warning if all fields are valid
    const currentWarning = getBlockWarning(block);
    if (isValid && currentWarning && !warningState.sequenceWarningVisible) {
      setBlockWarning(block, null);
    }

    return { isValid, warnings };
  }

  function areArraysEqual(arr1: number[][], arr2: number[][]): boolean {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].length !== arr2[i].length) return false;
      for (let j = 0; j < arr1[i].length; j++) {
        if (arr1[i][j] !== arr2[i][j]) return false;
      }
    }

    return true;
  }

  // Function to check if block order is valid
  function isBlockOrderValid(block: Blockly.Block): { isValid: boolean; warnings: string[] } {
    const currentType = block.type as BlockType;
    const nextBlock = block.getNextBlock();
    const previousBlock = block.getPreviousBlock();
    let isValid = true;
    const warnings: string[] = [];

    if (previousBlock) {
      const validPrevConnections = Object.entries(VALID_BLOCK_ORDER)
        .filter(([_, validNext]) => validNext.includes(currentType))
        .map(([blockType]) => blockType);

      if (!validPrevConnections.includes(previousBlock.type)) {
        isValid = false;
        const requiredPrevBlocks = validPrevConnections
          .map(type => BLOCK_DISPLAY_NAMES[type as BlockType])
          .join(' or ');
        warnings.push(requiredPrevBlocks ?
          `This block must be connected after: ${requiredPrevBlocks}` :
          'This block should be on top');
      }
    }

    if (nextBlock) {
      const validNextBlocks = VALID_BLOCK_ORDER[currentType] || [];
      if (!validNextBlocks.includes(nextBlock.type as BlockType)) {
        isValid = false;
        if (currentType === 'when_color_is') {
          warnings.push('Programs must start with "When Color Is" block');
        } else {
          const allowedNextBlocks = validNextBlocks
            .map(type => BLOCK_DISPLAY_NAMES[type])
            .join(' or ');
          warnings.push(allowedNextBlocks ?
            `Next block must be: ${allowedNextBlocks}` :
            'No blocks can be connected after this one');
        }
      }
    }

    return { isValid, warnings };
  }

export const initializeBlockValidation = () => {
  // Updated block validation extension
  Blockly.Extensions.register('block_validation', function (this: Blockly.Block) {
    this.setOnChange(function (this: Blockly.Block, event: Blockly.Events.Abstract) {
      // Early return if block is in flyout or workspace is not available
      if (!this.workspace || this.isInFlyout) return;

      // Check sequence state on any change

      const warnings: string[] = [];
      let isValid = true;

      // Always check block order
      const orderValidation = isBlockOrderValid(this);
      isValid = isValid && orderValidation.isValid;

      // Only add order warnings if not showing sequence warning
      if (!warningState.sequenceWarningVisible) {
        warnings.push(...orderValidation.warnings);
      }

      // Check fields only if validation is triggered and we have a complete sequence
      if (validationState.shouldCheckFields && !warningState.sequenceWarningVisible) {
        const fieldValidation = validateFields(this);
        isValid = isValid && fieldValidation.isValid;

        // Only add field warnings if there are any
        if (!fieldValidation.isValid) {
          warnings.push(...fieldValidation.warnings);
        }
      }

      // Clear warnings if everything is valid
      const currentWarning = getBlockWarning(this);
      if (isValid && currentWarning && !warningState.sequenceWarningVisible) {
        this.setWarningText(null);
        setBlockWarning(this, null);
      }
      // Set warning text only if there are warnings and not showing sequence warning
      else if (!warningState.sequenceWarningVisible && warnings.length > 0) {
        const warningText = warnings.join('\n');
        this.setWarningText(warningText);
        setBlockWarning(this, warningText);
      }


      // Handle block enabling/disabling
      if (!this.isInFlyout) {
        const initialGroup = Blockly.Events.getGroup();
        Blockly.Events.setGroup(event.group);

        if (validationState.shouldCheckFields) {
          this.setEnabled(isValid);
          let child = this.getNextBlock();
          while (child) {
            child.setEnabled(isValid);
            child = child.getNextBlock();
          }
        } else {
          this.setEnabled(true);
          let child = this.getNextBlock();
          while (child) {
            child.setEnabled(true);
            child = child.getNextBlock();
          }
        }

        Blockly.Events.setGroup(initialGroup);
      }
    });
  });
};
