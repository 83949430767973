
interface BlockPosition {
  id: string;
  position: number[];
  rotation: number[];
  url: string;
}

export class AssemblyValidator {
  private readonly positionTolerance = 0.5;
  private readonly rotationTolerance = 0.1;
  private matchedRequiredBlocks: Set<string> = new Set();

  constructor(
    private requiredBlocks: BlockPosition[],
    private currentBlocks: BlockPosition[]
  ) { }

  private findMatchingRequiredBlock(currentBlock: BlockPosition): BlockPosition | undefined {
    // Sort potential matches by distance to current block
    const potentialMatches = this.requiredBlocks
      .filter(required => 
        required.url === currentBlock.url && 
        !this.matchedRequiredBlocks.has(required.id)
      )
      .map(block => ({
        block,
        distance: this.getDistance(currentBlock.position, block.position)
      }))
      .sort((a, b) => a.distance - b.distance);

    if (potentialMatches.length > 0) {
      const match = potentialMatches[0].block;
      this.matchedRequiredBlocks.add(match.id);
      return match;
    }

    return undefined;
  }

  private getDistance(pos1: number[], pos2: number[]): number {
    return Math.sqrt(
      Math.pow(pos1[0] - pos2[0], 2) +
      Math.pow(pos1[1] - pos2[1], 2) +
      Math.pow(pos1[2] - pos2[2], 2)
    );
  }

  public validateAssembly(): {
    isValid: boolean;
    errors: string[];
    correctPlacements: string[];
    stage: 'availability' | 'placement';
  } {
    // Reset matched blocks at the start of validation
    this.matchedRequiredBlocks.clear();

    // First check if all required blocks are available
    const availabilityCheck = this.validateBlockAvailability();
    if (!availabilityCheck.isValid) {
      return {
        isValid: false,
        errors: availabilityCheck.errors,
        correctPlacements: [],
        stage: 'availability'
      };
    }

    const errors: string[] = [];
    const correctPlacements: string[] = [];

    // Sort current blocks by distance to their nearest required block
    const sortedCurrentBlocks = [...this.currentBlocks].sort((a, b) => {
      const aDistance = Math.min(...this.requiredBlocks
        .filter(req => req.url === a.url)
        .map(req => this.getDistance(a.position, req.position)));
      const bDistance = Math.min(...this.requiredBlocks
        .filter(req => req.url === b.url)
        .map(req => this.getDistance(b.position, req.position)));
      return aDistance - bDistance;
    });

    // Check position and rotation for each current block
    for (const currentBlock of sortedCurrentBlocks) {
      const requiredBlock = this.findMatchingRequiredBlock(currentBlock);

      if (!requiredBlock) {
        const blockName = currentBlock.url.split('/').pop()?.replace('.glb', '');
        errors.push(`Extra ${blockName} block detected`);
        continue;
      }

      const positionCorrect = this.comparePositions(currentBlock.position, requiredBlock.position);
      const rotationCorrect = this.compareRotations(currentBlock.rotation, requiredBlock.rotation);

      if (!positionCorrect || !rotationCorrect) {
        const blockName = currentBlock.url.split('/').pop()?.replace('.glb', '');
        if (!positionCorrect) {
          errors.push(`Block ${blockName} is not in correct position`);
        }
        if (!rotationCorrect) {
          errors.push(`Block ${blockName} is not in correct rotation`);
        }
      } else {
        correctPlacements.push(currentBlock.id);
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
      correctPlacements,
      stage: 'placement'
    };
  }

  // Rest of the class methods remain the same...
  private validateBlockAvailability(): {
    isValid: boolean;
    errors: string[];
  } {
    const errors: string[] = [];
    const requiredCounts = this.getBlockCounts(this.requiredBlocks);
    const currentCounts = this.getBlockCounts(this.currentBlocks);

    requiredCounts.forEach((requiredCount, url) => {
      const currentCount = currentCounts.get(url) || 0;
      if (currentCount < requiredCount) {
        const blockName = url.split('/').pop()?.replace('.glb', '');
        const missing = requiredCount - currentCount;
        errors.push(
          `Missing ${missing} ${blockName} block${missing > 1 ? 's' : ''} (${missing} more needed)`
        );
      }
    });

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  private getBlockCounts(blocks: BlockPosition[]): Map<string, number> {
    const counts = new Map<string, number>();
    blocks.forEach(block => {
      const count = counts.get(block.url) || 0;
      counts.set(block.url, count + 1);
    });
    return counts;
  }

  private normalizeRotation(rotation: number): number {
    return ((rotation % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
  }

  private comparePositions(current: number[], required: number[]): boolean {
    if (current.length !== 3 || required.length !== 3) return false;
    return current.every((coord, i) =>
      Math.abs(coord - required[i]) <= this.positionTolerance
    );
  }

  private compareRotations(current: number[], required: number[]): boolean {
    if (current.length !== 3 || required.length !== 3) return false;
    return current.every((angle, i) => {
      const normalizedCurrent = this.normalizeRotation(angle);
      const normalizedRequired = this.normalizeRotation(required[i]);
      const diff = Math.abs(normalizedCurrent - normalizedRequired);
      return diff <= this.rotationTolerance ||
        Math.abs(diff - 2 * Math.PI) <= this.rotationTolerance;
    });
  }

  public getBlockStatus(blockId: string): {
    isCorrect: boolean;
    position: boolean;
    rotation: boolean;
  } {
    // Reset matched blocks to ensure fresh matching
    this.matchedRequiredBlocks.clear();
    
    const currentBlock = this.currentBlocks.find(b => b.id === blockId);
    if (!currentBlock) {
      return { isCorrect: false, position: false, rotation: false };
    }

    const requiredBlock = this.findMatchingRequiredBlock(currentBlock);
    if (!requiredBlock) {
      return { isCorrect: false, position: false, rotation: false };
    }

    const positionCorrect = this.comparePositions(currentBlock.position, requiredBlock.position);
    const rotationCorrect = this.compareRotations(currentBlock.rotation, requiredBlock.rotation);

    return {
      isCorrect: positionCorrect && rotationCorrect,
      position: positionCorrect,
      rotation: rotationCorrect
    };
  }
}