// Toolbox configuration
export const toolboxConfiguration1 = {
  contents: [
    {
      kind: 'category',
      name: 'Light',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#6E3ECA] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'turn_on_for_seconds',
        },
        {
          kind: 'block',
          type: 'write_text',
        },
        {
          kind: 'block',
          type: 'set_power_button_light',
        },
      ],
    },
  ],
};

export const toolboxConfiguration2 = {
  // kind: "flyoutToolbox",
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#EDC643] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_color_is',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Light',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#6E3ECA] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'turn_on_for_seconds',
        },
        {
          kind: 'block',
          type: 'write_text',
        },
        {
          kind: 'block',
          type: 'set_power_button_light',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sound',
      id: '3',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#904DBE] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'play_sound_until_done',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#C5912E] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'stop_action',
        },
      ],
    },
  ],
};
