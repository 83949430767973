import { javascriptGenerator } from 'blockly/javascript';

export const initializeGenerators = () => {
  javascriptGenerator.forBlock['turn_on_for_seconds'] = function (block, generator) {
    const seconds = block.getFieldValue('text');
    
    return `async function turnOnForSeconds() {
      turnOn(${block.getFieldValue('turn_on_bitmap')});
      await new Promise(resolve => setTimeout(resolve, ${seconds} * 1000));
      turnOff(${block.getFieldValue('turn_on_bitmap')});
    }
    turnOnForSeconds();\n`;
  };
};