import ReactDOM from 'react-dom/client';
import './assets/css/index.css';

import Editor3D from './components/Editor3D';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <>
    {/* <React.StrictMode> */}
    <Toaster />
    <Editor3D />,
    {/* </React.StrictMode>, */}
  </>
);
