export let Lesson1 = [
    {
        id: "/models/4519.glb-1736484494685",
        position: [3, 10, 1],
        rotation: [-0.010524085813513042, 0.00995838890998665, 1.5731120119646262],
        url: "/models/4519.glb"
    },
    {
        id: "/models/32498.glb-1736484490389",
        position: [-1, 10, 1],
        rotation: [3.1081009805294832, -0.004972794983621941, 1.561629873628705],
        url: "/models/32498.glb"
    },
    {
        id: "/models/32525.glb-1736485581480",
        position: [0, 1, -9],
        rotation: [0, 0, 0],
        url: "/models/32525.glb"
    },
    {
        id: "/models/55615.glb-1736485588715",
        position: [1, 8, 13],
        rotation: [0.0068351985733178106, 0.012228248826461443, 1.593525184767114],
        url: "/models/55615.glb"
    },
    {
        id: "/models/48989.glb-1736485589095",
        position: [-1, 4, -7],
        rotation: [-1.5894184662077113, -0.003044856637122764, -1.5944597011217994],
        url: "/models/48989.glb"
    },
    {
        id: "/models/48989.glb-1736485677287",
        position: [1, 4, 9],
        rotation: [1.5598942020924977, 0.03807552341213485, 1.596954609901694],
        url: "/models/48989.glb"
    },
    {
        id: "/models/41239.glb-1736485910236",
        position: [0, 5, -11],
        rotation: [0, 0, 0],
        url: "/models/41239.glb"
    },
    {
        id: "/models/2780.glb-1736485993355",
        position: [0, 5, -1],
        rotation: [0, 0, 0],
        url: "/models/2780.glb"
    },
    {
        id: "/models/2780.glb-1736486002263",
        position: [0, 5, 3],
        rotation: [0, 0, 0],
        url: "/models/2780.glb"
    },
    {
        id: "/models/39793.glb-1736486116293",
        position: [-1, 10, 1],
        rotation: [-0.004694220268498048, -0.025558552276058442, -1.5440229337590716],
        url: "/models/39793.glb"
    },
    {
        id: "/models/2780.glb-1736486124890",
        position: [0, 14, 12],
        rotation: [1.5634314606885789, -0.005939046613685508, -0.01422863508162889],
        url: "/models/2780.glb"
    },
    {
        id: "/models/55615.glb-1736486365806",
        position: [-1, 8, -11],
        rotation: [3.1337966393220817, -0.0634688832822555, -1.5709534117547683],
        url: "/models/55615.glb"
    },
    {
        id: "/models/32009.glb-1736489172823",
        position: [0, 18, 16],
        rotation: [1.5529988394249588, 0.0019330266258427709, -3.125339734150342],
        url: "/models/32009.glb"
    },
    {
        id: "/models/32009.glb-1736489438100",
        position: [0, 18, -12],
        rotation: [1.5495559519281359, -0.01819670143722993, -3.114979065749761],
        url: "/models/32009.glb"
    },
    {
        id: "/models/2780.glb-1736489457256",
        position: [0, 14, -10],
        rotation: [-1.6045610859233383, -0.005135774067272217, -0.04545883380711447],
        url: "/models/2780.glb"
    },
    {
        id: "/models/32526.glb-1736489935313",
        position: [-4, 22, -10],
        rotation: [1.5616560212226198, 0.021462334794018278, 3.0832659282761967],
        url: "/models/32526.glb"
    },
    {
        id: "/models/32526.glb-1736490135517",
        position: [-4, 22, 14],
        rotation: [1.538447632721407, 0.013429344033933671, -3.135665708595734],
        url: "/models/32526.glb"
    },
    {
        id: "/models/32270.glb-1736490920031",
        position: [1, 10, 1],
        rotation: [-0.0021498371341258805, -0.006725938516429624, -1.5456633254128918],
        url: "/models/32270.glb"
    },
    {
        id: "/models/3749.glb-1736490925324",
        position: [0, 18, -10],
        rotation: [-1.5715115730343923, -0.06293337117053786, -0.0025041336843001504],
        url: "/models/3749.glb"
    },
    {
        id: "/models/3749.glb-1736490924613",
        position: [0, 18, 12],
        rotation: [1.604943929351303, 0.006018763608153117, -0.009787073788401603],
        url: "/models/3749.glb"
    },

]
export let Lesson2 = [
    {
        "id": "/models/39369.glb-1737090314923",
        "position": [10,1,-18],
        "rotation": [0,0,0],
        "url": "/models/39369.glb",
        "isModify": false
    },
    {
        "id": "/models/2780.glb-1737090515541",
        "position": [10,1,18],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/48989.glb-1737090574098",
        "position": [5,4,10],
        "rotation": [1.5707963267948966,1.1102230246251565e-16,1.5707963267948963],
        "url": "/models/48989.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090759839",
        "position": [10,1,-2],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090762162",
        "position": [10,1,2],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090762630",
        "position": [10,1,6],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090763157",
        "position": [10,1,10],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/48989.glb-1737090857966",
        "position": [-5,4,10],
        "rotation": [1.570796326794897,3.3306690738754696e-16,-1.5707963267948966],
        "url": "/models/48989.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090953897",
        "position": [-10,1,-18],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090955295",
        "position": [-10,1,18],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090955703",
        "position": [-10,1,6],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090956047",
        "position": [-10,1,10],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737090960712",
        "position": [10,1,-18],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737091139730",
        "position": [-10,1,-2],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/2780.glb-1737091158779",
        "position": [-10,1,2],
        "rotation": [0,0,0],
        "url": "/models/2780.glb",
        "isModify": true
    },
    {
        "id": "/models/40490.glb-1737091203829",
        "position": [10,3,-18],
        "rotation": [0,0,0],
        "url": "/models/40490.glb",
        "isModify": true
    },
    {
        "id": "/models/40490.glb-1737091261378",
        "position": [-10,3,-18],
        "rotation": [0,0,0],
        "url": "/models/40490.glb",
        "isModify": true
    },
    {
        "id": "/models/32523.glb-1737091307090",
        "position": [10,3,4],
        "rotation": [0,0,0],
        "url": "/models/32523.glb",
        "isModify": true
    },
    {
        "id": "/models/32523.glb-1737091332259",
        "position": [-10,3,4],
        "rotation": [0,0,0],
        "url": "/models/32523.glb",
        "isModify": true
    },
    {
        "id": "/models/32316.glb-1737091634200",
        "position": [10,3,14],
        "rotation": [0,0,0],
        "url": "/models/32316.glb",
        "isModify": true
    },
    {
        "id": "/models/32316.glb-1737091646118",
        "position": [-10,3,14],
        "rotation": [0,0,0],
        "url": "/models/32316.glb",
        "isModify": true
    },
    {
        "id": "/models/53444c01.glb-1737092083952",
        "position": [7,6,13],
        "rotation": [-1.5707963267948963,-1.5707963267948966,0],
        "url": "/models/53444c01.glb",
        "isModify": true
    },
    {
        "id": "/models/55422c01.glb-1737093802149",
        "position": [1,16,13],
        "rotation": [-1.570796326794897,1.5707963118937354,0],
    }
]


// Create a mapping between lesson IDs and their corresponding arrays
export const getLessonBlocks = (lessonId) => {
    const lessonMap = {
        '1': Lesson1,
        '2': Lesson2,
        // Add more lessons as needed
    };

    return lessonMap[lessonId] || Lesson1; // Default to Lesson1 if invalid lessonId
};