import React, {useRef, useEffect, useState} from 'react';
import * as THREE from 'three';
import {useLoader, useFrame} from '@react-three/fiber';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

import { GLBModelProps } from './types';



const GLBModel: React.FC<GLBModelProps> = ({ url, filename, animation, position }) => {
  const ref = useRef<THREE.Group>();
  const [error, setError] = useState<string | null>(null);

  const gltf = useLoader(GLTFLoader, url, undefined, event => {
    if (event.type === 'progress') {
      // Handle progress event if needed
    } else {
      console.error(`Error loading model ${url}:`, event);
      setError(`Failed to load model: ${event.type}`);
    }
  });

  useEffect(() => {
    if (gltf) {
      if (!gltf.scene || !gltf.scene.children.length) {
        setError('Loaded model has no visible content');
      }
    }
  }, [gltf, filename]);

  useFrame(() => {
    if (ref.current && animation) {
      ref.current.rotation.y += 0.01;
    }
  });

  if (error) {
    console.warn(`Model ${filename} not loaded: ${error}`);
    return (
      <mesh position={position}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="red" />
      </mesh>
    );
  }

  if (!gltf || !gltf.scene) {
    return null;
  }

  return <primitive object={gltf.scene} ref={ref} position={position} />;
};

export default GLBModel;
