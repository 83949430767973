import React from 'react';
import {Canvas} from '@react-three/fiber';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa6';

import GLBModel from './GLBModel';
import { PartListProps } from './types';


const PartList: React.FC<PartListProps> = React.memo(
  ({parts, inventory, onPartClick, setPage, currentPage, lastPage}) => {
    return (
      <div className="absolute bottom-4 overflow-auto left-1/2 transform -translate-x-1/2 bg-white rounded-full shadow-lg py-2 px-4 max-w-full-calc">
        <div className="flex space-x-4 items-center">
          {parts.length === 0 ? (
            [...Array(10)].map((_, index) => (
              <div key={index} className="relative">
                {/* Placeholder for the button/3D model */}
                <div className="w-20 h-20 bg-gray-200 rounded-full overflow-hidden flex-shrink-0 animate-pulse">
                  <div className="w-full h-full bg-gray-300"></div>
                </div>
                {/* Placeholder for remaining quantity */}
                <div className="absolute bottom-0 right-0 bg-gray-400 text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center shadow-md z-10 transform translate-x-1/4 translate-y-1/4 animate-pulse">
                  <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
                </div>
              </div>
            ))
          ) : (
            <>
              <FaAngleLeft
                type="button"
                className={`${
                  currentPage !== 1 ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                onClick={() => {
                  if (currentPage !== 1) {
                    setPage(currentPage - 1);
                  }
                }}
              />
              {parts.map((piece, index) => {
                const remainingQuantity = inventory[piece.filename] || 0;
                const isDisabled = remainingQuantity === 0;
                return (
                  <div key={index} className="relative">
                    <button
                      className={`w-20 h-20 bg-gray-100 rounded-full overflow-hidden flex-shrink-0 ${
                        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      onClick={() => !isDisabled && onPartClick(piece.filename)}
                      disabled={isDisabled}>
                      <Canvas camera={{position: [4, 5, 5], fov: 80}}>
                        <ambientLight intensity={0.2} />
                        <pointLight position={[10, 10, 10]} />
                        <GLBModel
                          url={piece.filename}
                          filename={piece.filename}
                          animation={true}
                          position={[0, 0, 0]}
                        />
                      </Canvas>
                    </button>
                    <div className="absolute bottom-0 right-0 bg-blue text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center shadow-md z-10 transform translate-x-1/4 translate-y-1/4">
                      {remainingQuantity}
                    </div>
                  </div>
                );
              })}
              <FaAngleRight
                type="button"
                className={`${
                  lastPage !== currentPage
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed'
                }`}
                onClick={() => {
                  if (currentPage < lastPage) {
                    setPage(currentPage + 1);
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);

export default PartList;
