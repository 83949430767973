  import { useEffect } from 'react';
  import * as Blockly from 'blockly';


  export const WORKSPACE_STATE_KEY = 'blocklyWorkspaceState';

  export const useToolboxManager = (workspace: Blockly.WorkspaceSvg | null, initialState:any) => {
    // Initialize workspace with saved state
    useEffect(() => {
      if (workspace && initialState?.items) {
        try {
          // Clear existing workspace
          workspace.clear();
          // Load the saved state
          Blockly.serialization.workspaces.load(initialState?.items, workspace);
        } catch (error) {
          console.error('Error restoring workspace state:', error);
        }
      }
    }, [workspace, initialState?.items]);

    useEffect(() => {
      if (!workspace) return;

      const toolboxElement: any = workspace.getToolbox();
      let selectedIndex: number | null = null;

      const handleCategorySelection = () => {
        const selectedCategory: any = toolboxElement.getSelectedItem();
        if (selectedCategory) {
          selectedIndex = toolboxElement.getToolboxItems().findIndex(
            (item: any) => item.toolboxItemDef_.id === selectedCategory.toolboxItemDef_.id
          );
        }
      };

      const flyoutWorkspace = toolboxElement.getFlyout()?.getWorkspace();

      const toolboxItems = toolboxElement.getToolboxItems();
      if (toolboxItems.length > 0) {
        toolboxElement.selectItemByPosition(0);
        selectedIndex = 0;
      }

      flyoutWorkspace?.addChangeListener(handleCategorySelection);

      const handleWorkspaceChange = (event: any) => {
        if ([Blockly.Events.BLOCK_DRAG, Blockly.Events.DELETE, Blockly.Events.MOVE].includes(event.type)) {
          if (selectedIndex !== null) {
            const safeIndex = Math.min(Math.max(selectedIndex, 0), toolboxItems.length - 1);
            toolboxElement.selectItemByPosition(safeIndex);
          }
        }
      };

      workspace.addChangeListener(handleWorkspaceChange);

      return () => {
        flyoutWorkspace?.removeChangeListener(handleCategorySelection);
        workspace.removeChangeListener(handleWorkspaceChange);
      };
    }, [workspace]);
  };