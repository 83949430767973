import React, { useState, useEffect, useRef } from 'react';
import { FaAlignLeft, FaTimes } from 'react-icons/fa';
import { FaRegCirclePlay, FaQuestion } from 'react-icons/fa6';
import * as Blockly from 'blockly';
import WordblocksPreview from '../model/WordblocksPreview';
import HotKeys from './HotKeys';
import Editor2D from "./Editor2D"
import { onValidationButtonClick } from './Blockly/BlockValidation';
import toast from 'react-hot-toast';

const DropdownExample: React.FC = () => {
  const [isOpenPlay, setIsOpenPlay] = useState(false);
  const [isOpenHotkey, setIsOpenHotkey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [initialState, setInitialState] = useState<any>();

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const toggleOpenPlay = () => {
    if (isOpen) {
      const workspace = Blockly.getMainWorkspace();
      let checkValidation: any = onValidationButtonClick(workspace)
      if (checkValidation) {
        setIsOpen(false);
        setIsOpenPlay(!isOpenPlay);
      }
    } else {
      if (initialState?.is_valid) {
        setIsOpenPlay(!isOpenPlay);
      } else {
        toast.error("Please Check Block Order And Values", { duration: 1000, id: "playbutton" })
      }
    }
  };

  const toggleOpenHotkey = () => {
    setIsOpenHotkey(!isOpenHotkey);
  };

  // Reference to the modal to detect outside clicks
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const fetchWorkspaceState = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const lessonParam = urlParams.get('lessonId');
      const userIDParam = urlParams.get('userId');
      const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
      const userID = userIDParam ? parseInt(userIDParam) : 1;
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/block-action-model/${lessonNumber}/${userID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch workspace state');
        }
        const data = await response.json();
        setInitialState(data?.data);
      } catch (error) {
        console.error('Error fetching workspace state:', error);
      } 
    };

    fetchWorkspaceState();
  }, []);

  // Close the modal if the click is outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If the modal is open and the click happens outside the modalRef
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsOpenHotkey(false); // Close the modal
      }
    };

    if (isOpenHotkey) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up event listener on component unmount or modal close
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenHotkey]);

  return (
    <div className="absolute top-4 right-2 sm:right-5 left-2 sm:left-5 lg:left-auto z-10">
      <div className="flex space-x-6 justify-end mb-8">
        <button
          className={`btn btn-circle ${isOpenHotkey ? 'active_btn_hotkey' : ''}`}
          onClick={toggleOpenHotkey}
        >
          <FaQuestion style={{ width: '100%' }} />
        </button>
        <button
          className={`btn btn-circle ${isOpenPlay ? 'active_btn' : ''}`}
          onClick={toggleOpenPlay}>
          <FaRegCirclePlay style={{ width: '100%' }} />
        </button>
        <button
          className={`btn btn-circle ${isOpen ? 'active_btn' : ''}`}
          onClick={toggleDropdown}>
          <FaAlignLeft />
        </button>
        <button
          onClick={() => {
            setIsOpen(false);
            setIsOpenHotkey(false);
          }}>
          <FaTimes />
        </button>
      </div>

      {isOpen && (
        <div className="model_box">
          <Editor2D initialState={initialState} setInitialState={setInitialState} />
        </div>
      )}
      {isOpenPlay && <WordblocksPreview onClose={() => setIsOpenPlay(false)} />}

      {isOpenHotkey && (
        <div ref={modalRef}>
          <HotKeys onClose={() => setIsOpenHotkey(false)} />
        </div>
      )}

    </div>
  );
};

export default DropdownExample;
