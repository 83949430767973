
export type BlockType = 'when_color_is' | 'set_power_button_light' | 'write_text' | 'play_sound_until_done' | 'turn_on_for_seconds' | 'stop_action';

export interface BlockOrderMap {
  [key: string]: BlockType[];
}

interface BaseFieldValidationRule {
  fieldName: string;
  errorMessage: string;
}

interface SimpleFieldValidationRule extends BaseFieldValidationRule {
  type: 'simple';
  expectedValue:  string | string[];
}

interface BitmapFieldValidationRule extends BaseFieldValidationRule {
  type: 'bitmap';
  expectedPattern: number[][];
}

type FieldValidationRule = SimpleFieldValidationRule | BitmapFieldValidationRule;


export interface ValidationState {
  shouldCheckFields: boolean;
}

// Add warning state management
export interface WarningState {
  sequenceWarningVisible: boolean;
  lastValidationTime: number;
  blockWarnings: Map<string, string | null>;
}


// Define block display names for more readable tooltips
export const BLOCK_DISPLAY_NAMES: Record<BlockType, string> = {
  'when_color_is': 'When Color Is',
  'set_power_button_light': 'Set Power Button Light',
  'write_text': 'Write Text',
  'play_sound_until_done': 'Play Sound',
  'turn_on_for_seconds': 'Turn On For Seconds',
  'stop_action': 'Stop Action'
};

// Define the valid block order with proper typing
export const VALID_BLOCK_ORDER: BlockOrderMap = {
  'when_color_is': ['set_power_button_light'],
  'set_power_button_light': ['write_text'],
  'write_text': ['play_sound_until_done'],
  'play_sound_until_done': ['turn_on_for_seconds'],
  'turn_on_for_seconds': ['stop_action'],
  'stop_action': []
};

// Define validation rules for each block type
export const FIELD_VALIDATION_RULES: Record<BlockType, FieldValidationRule[]> = {
  'when_color_is': [
    {
      type: 'simple',
      fieldName: 'WHEN_COLOR_DROPDOWN',
      expectedValue: ['F',"f"],
      errorMessage: 'Button must be set to "F"'
    },
    {
      type: 'simple',
      fieldName: 'WHEN_COLOR_PICKER',
      expectedValue: ['#4d1fa7'],
      errorMessage: 'Color must be set to Blue'
    }
  ],
  'set_power_button_light': [
    {
      type: 'simple',
      fieldName: 'SET_POWER_COLOR_PICKER',
      expectedValue: ['#4d1fa7'],
      errorMessage: 'Power button light must be set to Blue'
    }
  ],
  'write_text': [
    {
      type: 'simple',
      fieldName: 'WRITE_TEXT',
      expectedValue: ['Blue', "blue", "BLUE"],
      errorMessage: 'Text must be "Blue"'
    }
  ],
  'play_sound_until_done': [
    {
      type: 'simple',
      fieldName: 'SOUND_NAME',
      expectedValue: ['Blue', "blue"],
      errorMessage: 'Sound must be set to Blue'
    }
  ],
  'turn_on_for_seconds': [
    {
      type: 'bitmap',
      fieldName: 'TURN_ON_BITMAP',
      expectedPattern: [
        [1, 1, 1, 0, 0],
        [1, 0, 0, 1, 0],
        [1, 1, 1, 0, 0],
        [1, 0, 0, 1, 0],
        [1, 1, 1, 0, 0]
      ],
      errorMessage: 'Pattern must be set to "B"'
    },
    {
      type: 'simple',
      fieldName: 'TURN_ON_SECOND',
      expectedValue: ['0.25', ".25"],
      errorMessage: 'Duration must be 0.25 seconds'
    }
  ],
  'stop_action': [
    {
      type: 'simple',
      fieldName: 'STOP',
      expectedValue: ['All', "all", "ALL"],
      errorMessage: 'Stop action must be set to "All"'
    }
  ]
};